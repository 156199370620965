<template>
  <div>
    <v-autocomplete
      v-model="busqueda"
      class="gMapsAutocompletePlaces"
      clearable
      :disabled="disabled"
      :error-messages="error"
      :hint="hint"
      item-text="description"
      item-value="place_id"
      :items="predicciones"
      :label="label ? label : 'Buscar lugar'"
      :loading="cargando"
      no-filter
      filled
      :persistent-hint="persistentHint"
      prepend-inner-icon="place"
      :search-input.sync="buscar"
      @change="_searchAndSendPlace"
    />
    <!-- <v-btn
      text
      small
      v-text="'Mi ubicación es el lugar de la consulta'"
      @click="_getCoords"
    /> -->
  </div>
</template>
<script>
const error = "Sucedió un error al consultar las predicciones";
import forms from "@/mixins/forms.js";
export default {
  name: "gMapsAutocompletePlaces",
  mixins: [forms],
  props: {
    disabled: Boolean,
    error: String,
    getAddressFromCoords: Boolean,
    hint: String,
    label: String,
    persistentHint: Boolean,
  },
  data() {
    return {
      predicciones: [],
      busqueda: null,
      cargando: false,
      buscar: null,
      place_id: null,
      lugar: {
        description: null,
        place_id: null,
        types: null,
        place_details: {},
      },
      gettingPlaceDetail: false,
      latitude: null,
      longitude: null,
    };
  },
  methods: {
    async _getPredicciones() {
      this.cargando = true;
      try {
        const { status, body } = await this.$http.post("maps/autocomplete", {
          lugar: this.buscar,
        });
        this.cargando = false;
        this.predicciones =
          (status === 200 || status === 201) && "predicciones" in body
            ? body.predicciones
            : [];
      } catch (err) {
        this.cargando = false;
        this.$emit("httpError", err);
      }
    },
    async _getPlaceDetail(place_id) {
      this.cargando = true;
      this.gettingPlaceDetail = true;
      try {
        const data = { place_id: place_id ? place_id : this.place_id };
        const { status, body } = await this.$http.post("maps/placeID", data);
        this.cargando = false;
        this.gettingPlaceDetail = false;
        if (status === 200 && "place_details" in body) {
          this.lugar.place_details = body.place_details;
          this.$emit("change", this.lugar);
        } else {
          this.$emit(
            "msg",
            "Sucedió un error al obtener la información del domiclio seleccionado"
          );
        }
      } catch (err) {
        this.cargando = false;
        this.gettingPlaceDetail = false;
        this.$emit("msg", error);
      }
    },
    _searchAndSendPlace(a) {
      this.place_id = a;
      this._getPlaceDetail(a);
      let i = this.predicciones.findIndex(function (b) {
        return b.place_id === a;
      });
      if (i >= 0) {
        this.lugar.description = this.predicciones[i].description;
        this.lugar.place_id = this.predicciones[i].place_id;
        this.lugar.types = this.predicciones[i].types;
        // this.$emit("change", this.predicciones[i]);
      }
    },
    _getCoords() {
      const errorNoLocacion =
        "No pudimos obtener las coordenadas.<br>Ingresa manualmente el domicilio.";
      if (!("geolocation" in navigator)) {
        this.$emit("msg", errorNoLocacion);
        return;
      }
      this.gettingLocation = true;
      //sin promise sync await porque seria mucho codigo para algo breve
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const { latitude, longitude } = pos.coords;
          this.latitude = latitude;
          this.longitude = longitude;
          this._convertLatLongToAddress();
          this.gettingLocation = false;
        },
        (err) => {
          this.gettingLocation = false;
          this.$emit("msg", errorNoLocacion);
        }
      );
    },
    async _convertLatLongToAddress() {
      this.cargando = true;
      try {
        const { status, body } = await this.$http.get(
          "maps/coordstoaddress/?latitude=" +
            this.latitude +
            "&longitude=" +
            this.longitude
        );
        this.cargando = false;
        if (status && "address" in body) {
          const { locality, neighborhood, route, street_number } = body.address;
          this.frap.calle_fra = route;
          this.frap.num_ext_fra = street_number;
          this.frap.colonia_fra = neighborhood;
          this.frap.ciudad_fra = locality;
        }
      } catch (err) {
        this.cargando = false;
        this.$emit(
          "No pudimos obtener el domiicilio. Por favor ingresalo manualmente."
        );
      }
    },
  },
  watch: {
    buscar(a) {
      if (!this.gettingPlaceDetail && a && a != "" && a != " ") {
        this._getPredicciones();
      }
    },
  },
};
</script>