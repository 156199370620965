<template>
  <section
    class="AmbulanciasServiciosEspeciales empresas__inner container mt24 mb24"
  >
    <ButtonBackHome to="/empresas/ambulancia" text="Regresar" />
    <header class="empresas__header">
      <h2 class="empresas_home__heading heading">
        Solicitud de ambulancias para eventos especiales
      </h2>
      <div class="empresas_home__text">
        Te acompañaremos en tu evento durante el día y hora que nos indiques.<br /><span
          style="background:#ecb731"
          >Llena todos los campos y selecciona el lugar del evento según te sea
          mostrado. El precio del servicio puede aplicar cargos adicionales en
          caso de no respetar el horario indicado.</span
        >
      </div>
    </header>
    <v-card flat :loading="cargando" :disabled="cargando">
      <v-row>
        <v-col cols="12" sm="12">
          <gMapsAutocompletePlaces
            @change="_handleLugar($event, 'lugar_inicio_eva')"
            label="¿Dónde será el lugar del evento?*"
            hint="Es importante seleccione el lugar de la lista"
            persistentHint
          />
        </v-col>
        <v-col cols="12" sm="12">
          <v-text-field
            label="¿Algo que debamos saber sobre el lugar del evento?"
            v-model="eva.lugar_inicio_notas_eva"
            filled
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="8">
          <v-row>
            <v-col cols="12" sm="6">
              <AutocompleteTiposServiciosEspeciales
                :disabled="!eva.lugar_inicio_eva"
                v-model="ese.id_tse_ese"
                label="Tipo de evento*"
                hint="Por favor selecciona el tipo de evento al que acudiremos"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <DatePicker
                :disabled="!eva.lugar_inicio_eva"
                label="Fecha del evento*"
                v-model="eva.fecha_inicio_eva"
                sinceToday
                :addDays="diasPrevios"
                @change="_validateIfDateIsEnabled"
                :error="fechaOcupada ? textErrorFecha : null"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <TimePicker
                :disabled="!eva.fecha_inicio_eva"
                v-model="eva.hora_inicio_eva"
                label="Inicio del evento*"
                @change="_handleTimePrice"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <TimePicker
                :disabled="!eva.hora_inicio_eva"
                :horaInicio="eva.hora_inicio_eva"
                v-model="eva.hora_termino_eva"
                label="Término del evento*"
                @change="_handleTimePrice"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                :disabled="!eva.hora_termino_eva"
                v-model="cant_ambulancias_ese"
                :items="selectAmbu"
                label="Ambulancias requeridas*"
                filled
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                :disabled="!eva.hora_termino_eva"
                v-model="cant_paramedicos_ese"
                :items="selectPara"
                label="Paramédicos requeridos*"
                filled
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :disabled="!eva.hora_termino_eva"
                v-model="ese.responsable_ese"
                label="Organizador o responsable*"
                filled
                prepend-inner-icon="person_pin"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :disabled="!eva.hora_termino_eva"
                v-model="ese.contacto_ese"
                v-mask="'### ### ####'"
                label="Contacto*"
                filled
                hint="Teléfono a 10 dígitos"
                prepend-inner-icon="phone"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-card outlined flat class="mt12">
            <v-list subheader>
              <v-subheader v-text="'RESUMEN'" />
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="
                      `${cant_ambulancias_ese} Ambulancia` +
                        (cant_ambulancias_ese === 1 ? '' : 's') +
                        ` + ${cant_paramedicos_ese} Paramedicos`
                    "
                  />
                  <v-list-item-title
                    v-if="paramedicosAdicionales"
                    v-text="`${paramedicosAdicionales} paramédicos adicionales`"
                  />
                  <v-list-item-title
                    v-text="
                      `${horasServicio} hora${
                        horasServicio == 1 ? '' : 's'
                      } de servicio`
                    "
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="`<strong>Total:</strong> ${$nF(total)}*`"
                  />
                  <v-list-item-title class="nowrap mt4"
                    >*El precio de servicio puede aplicar cargos adicionales en
                    caso de no respetar el horario indicado.</v-list-item-title
                  >
                  <v-list-item-title class="nowrap mt4"
                    ><span style="background:#ecb731"
                      >*Para poder obtener la carta para realizar el trámite
                      ante protección civil es necesario realizar el pago y en
                      automático se envía al correo registrado.</span
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <small
        >No podrás reservar el evento si no estan llenos los campos indicados
        con *</small
      >
      <AmbulanciasReservarAcciones
        :tipo="tipo"
        :showActions="ese.responsable_ese && ese.contacto_ese.length >= 12"
        :showPayPalButton="false"
        :total="total"
        :eva="eva"
        :data.sync="ese"
        @msg="$emit('msg', $event)"
        @httpError="$emit('httpError', $event)"
      />
    </v-card>
    <dialog-loading v-model="revisandoFecha" :text="textFecha" />
  </section>
</template>
<script>
import standard from "@/mixins/standard";
import ambulanciasConfig from "@/mixins/ambulanciasConfig.js";
import HeaderTitle from "@/components/header/HeaderTitle.vue";
import AutocompleteTiposServiciosEspeciales from "@/components/forms/autocompletes/AutocompleteTiposServiciosEspeciales.vue";

export default {
  name: "AmbulanciasServiciosEspeciales",
  mixins: [standard, ambulanciasConfig],
  components: {
    HeaderTitle,
    AutocompleteTiposServiciosEspeciales,
  },
  data() {
    return {
      configuracion: {
        ambulancia_cose: null,
        anticipacion_dias_cose: null,
        cancelacion_falta_pago_cose: null,
        limite_diario_cose: null,
        ambulancia: {
          costo_ser: null,
          desc_ser: null,
          estatus_ser: null,
          id_cta_ser: null,
          id_ser: null,
          id_tin_ser: null,
          tipo_ser: null,
        },
        paramedico: {
          costo_ser: null,
          desc_ser: null,
          estatus_ser: null,
          id_cta_ser: null,
          id_ser: null,
          id_tin_ser: null,
          tipo_ser: null,
        },
      },
      tipo: "serviciosespeciales",
      ese: {
        id_eva_ese: null,
        id_tse_ese: null,
        cant_ambulancias_ese: null,
        cant_paramedicos_ese: null,
        horas_ese: null,
        responsable_ese: null,
        contacto_ese: null,
      },
      cant_ambulancias_ese: null,
      cant_paramedicos_ese: null,
      selectAmbu: [],
      selectPara: [],
      horasServicio: 0,
    };
  },
  methods: {
    _validateIfDateIsEnabled(value) {
      const disabledDates = [
        "2024-05-02",
        "2024-05-03",
        "2024-05-04",
        "2024-05-05",
        "2024-05-06",
        "2024-05-07",
        "2024-05-08",
        "2024-05-09",
        "2024-05-10",
        "2024-05-11",
        "2024-05-12",
        "2024-05-13",
        "2024-05-14",
        "2024-05-15",
        "2024-05-16",
        "2024-05-17",
        "2024-05-18",
        "2024-05-19",
      ];
      console.log("iunclde");
      console.log(disabledDates.includes(value));
      const isthere = disabledDates.includes(value);
      if (isthere) {
        this.eva.fecha_inicio_eva = null;
        this.fechaOcupada = true;
      } else {
        this.textErrorFecha = null;
        this._handleTimePrice(value);
      }
    },
    _handleTimePrice(value) {
      this._EstaLibreLaFecha(value);
      if (
        this.eva.fecha_inicio_eva &&
        this.eva.hora_inicio_eva &&
        this.eva.hora_termino_eva
      ) {
        var fecha = this.eva.fecha_inicio_eva;
        const hora_inicio = this.eva.hora_inicio_eva;
        const hora_fin = this.eva.hora_termino_eva;
        var fechaTermino = this.$Datetime.fromISO(
          fecha + "T" + hora_fin + ":00Z"
        );
        if (parseFloat(hora_inicio) > parseFloat(hora_fin)) {
          fechaTermino = this.$Datetime
            .fromISO(fecha + "T" + hora_fin + ":00Z")
            .plus({ days: 1 });
        }
        const fechaInicio = this.$Datetime.fromISO(
          fecha + "T" + hora_inicio + ":00Z"
        );
        const diff = this.$Interval
          .fromDateTimes(fechaInicio, fechaTermino)
          .length("hours");
        const horas = Math.ceil(diff);
        this.ese.horas_ese = horas;
        this.horasServicio = horas;
      }
    },
  },
  computed: {
    total() {
      const subtotalAmbulancia =
        this.cant_ambulancias_ese * this.configuracion.ambulancia.costo_ser;
      const paramedicos =
        this.cant_paramedicos_ese - this.cant_ambulancias_ese * 2;
      const subtotalParamedicos =
        this.configuracion.paramedico.costo_ser * paramedicos;
      return parseFloat(
        (subtotalAmbulancia + subtotalParamedicos) * this.horasServicio
      ).toFixed(2);
    },
    paramedicosAdicionales() {
      const cantAmbulancias = parseInt(this.cant_ambulancias_ese) * 2;
      const cantParamedicos = parseInt(this.cant_paramedicos_ese);
      return cantAmbulancias < this.cant_paramedicos_ese ? 1 : 0;
    },
  },
  watch: {
    cant_ambulancias_ese(cant) {
      let ambulancias = parseInt(cant);
      this.ese.cant_ambulancias_ese = ambulancias;
      this.cant_paramedicos_ese = ambulancias * 2;
      this.ese.cant_paramedicos_ese = ambulancias * 2;
      this.selectPara = [
        { value: ambulancias * 2, text: ambulancias * 2 },
        { value: ambulancias * 2 + 1, text: ambulancias * 2 + 1 },
      ];
    },
    cant_paramedicos_ese(cant) {
      this.ese.cant_paramedicos_ese = cant;
    },
    configuracion(a) {
      console.log("limite diario");
      console.log(a.limite_diario_cose);
      for (let index = 1; index < parseInt(a.limite_diario_cose) + 1; index++) {
        this.selectAmbu.push({ value: index, text: index });
      }
      this.cant_ambulancias_ese = 1;
    },
  },
  mounted() {
    this._getConfigAmbu(this.tipo);
  },
};
</script>
