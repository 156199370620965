<template>
  <div class="AutocompleteTiposServiciosEspeciales">
    <v-autocomplete
      v-if="!isSelect"
      v-model="valor"
      append-icon="search"
      :disabled="disabled"
      :error-messages="error"
      item-text="desc_tse"
      item-value="id_tse"
      :items="tipos"
      :label="label ? label : 'Buscar tipo de servicio especial'"
      filled
      @change="_searchAndSendTSE"
    />
    <v-select
      v-else
      v-model="valor"
      :disabled="disabled"
      :error-messages="error"
      item-text="desc_tse"
      item-value="id_tse"
      :items="tipos"
      :label="label ? label : 'Seleccionar tipo de servicio especial'"
      filled
      @change="_searchAndSendTSE"
    />
  </div>
</template>
<script>
export default {
  name: "AutocompleteTiposServiciosEspeciales",
  props: {
    value: String,
    isSelect: Boolean,
    label: String,
    error: String,
    disabled: Boolean,
  },
  data() {
    return {
      valor: null,
      cargando: false,
      tipos: [],
    };
  },
  methods: {
    _getTipos(estatus) {
      this.cargando = true;
      this.$http
        .get("ambulancias/tiposserviciosespeciales?" + estatus, this.$headers)
        .then(
          (res) => {
            this.cargando = false;
            if (res.status === 200 && "tipos" in res.body) {
              this.tipos = res.body.tipos;
            } else {
              this.$emit("httpError", res);
            }
          },
          (err) => {
            this.cargando = false;
            this.$emit("httpError", err);
          }
        );
    },
    _searchAndSendTSE(a) {
      this.$emit("input", a);
      let i = this.tipos.findIndex(function (b) {
        return b.id_tse === a;
      });
      if (i >= 0) {
        this.$emit("change", this.tipos[i]);
      }
    },
  },
  created() {
    this._getTipos();
  },
  watch: {
    value(a) {
      this.valor = a;
    },
  },
};
</script>