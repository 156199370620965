import forms from "@/mixins/forms.js";
import DialogLoading from '@/components/dialogs/DialogLoading.vue';
import AmbulanciasReservarAcciones from "@/components/ambulancias/AmbulanciasReservarAcciones.vue";
import gMapsAutocompletePlaces from "@/components/forms/gMaps/gMapsAutocompletePlaces.vue";
import DatePicker from "@/components/forms/pickers/DatePicker.vue";
import TimePicker from "@/components/forms/pickers/TimePicker.vue";
import PayPalCheckout from "@/components/paypal/PayPalCheckout.vue";
export default {
    mixins: [forms],
    components: {
        DialogLoading,
        AmbulanciasReservarAcciones, gMapsAutocompletePlaces, DatePicker, TimePicker, PayPalCheckout
    },
    data() {
        return {
            fechasNoDisponibles: [],
            pagarAntesDe: 0,
            idEva: null,
            cargando: false,
            eva: {
                id_eva: null,
                id_don_eva: null,
                id_cgo_eva: null,
                lugar_inicio_eva: null,
                lugar_inicio_notas_eva: null,
                lugar_termino_eva: null,
                lugar_termino_notas_eva: null,
                fecha_inicio_eva: null,
                hora_inicio_eva: null,
                fecha_termino_eva: null,
                hora_termino_eva: null,
                created_at_eva: null,
                updated_at_eva: null
            },
            selects: {
                tiposTraslado: [{ text: 'Sencillo', value: 'sencillo' }, { text: 'Completo', value: 'completo' }],
                condicionPaciente: [{ text: 'Critico', value: 'critico' }, { text: 'Estable', value: 'estable' }, { text: 'Covid', value: 'covid' }],
                requerimientosPac: [{ text: 'Oxigeno', value: 'oxigeno' }, { text: 'Canalizar', value: 'canalizar' }]
            },
            pagarAntesDe: 0,
            textFecha: 'Estamos revisando nuestra disponibilidad para atenderte el día que seleccionaste',
            textErrorFecha: 'Lo sentimos, el dia que seleccionaste no podremos antenderte debido a que tenemos todas nuestras ambulancias ocupadas. Prueba con otra fecha.',
            revisandoFecha: false,
            diasPrevios: 3,
            fechaOcupada: false,
        }
    },
    methods: {
        _EstaLibreLaFecha(fecha) {
            // this.revisandoFecha = true
            const found = this.fechasNoDisponibles.find(value => value === fecha);
            console.log(this.fechasNoDisponibles);
            if (found) {
                this.fechaOcupada = true
                this.$emit('msg', this.textErrorFecha);
                switch (this.tipo) {
                    case 'serviciosespeciales':
                        this.eva.fecha_inicio_eva = null
                        break;
                }
                // this.
            } else {
                this.fechaOcupada = false
            }
        },
        _hourToInt(h) {
            if (h) {
                h = h.split(":");
                return parseInt(h[0], 10);
            } else {
                return 0
            }
        },
        _handleLugar(place, variable) {
            this.eva[variable] = place;
        },
        async _getConfigAmbu(tipo) {
            if (tipo) {
                this.cargando = true
                try {
                    const { status, body } = await this.$http.get('ambulancias/configuracion/' + tipo, this.$headers);
                    this.cargando = false
                    if (status === 200 && 'configuracion' in body) {
                        const { configuracion } = body
                        this.configuracion = configuracion
                        switch (tipo) {
                            case "serviciosespeciales":
                                this.diasPrevios = configuracion.anticipacion_dias_cose
                                this.pagarAntesDe = configuracion.cancelacion_falta_pago_cose;
                                break;
                            case "trasladosforaneos":
                                this.diasPrevios = configuracion.anticipacion_dias_cose
                                this.pagarAntesDe = configuracion.cancelacion_falta_pago_cotf
                                break;
                            case "trasladoslocales":
                                this.diasPrevios = configuracion.anticipacion_dias_cose
                                this.pagarAntesDe = configuracion.cancelacion_falta_pago_cotl
                                break;
                            default:
                                break;
                        }
                        if ('nodisponible' in configuracion) {
                            this.fechasNoDisponibles = configuracion.nodisponible
                        }
                    } else {
                        this.$router.push('/');
                        this.$emit('msg', 'Sucedió un error al intentar cargar la información para los servicios de ambulancia. Inténtalo de nuevo más tarde.')
                    }
                } catch (err) {
                    this.cargando = false
                    this.$emit('httpError', err)
                }
            }
        }
    }
}