<template>
  <v-dialog
    v-model="value"
    persistent
    width="400"
  >
    <v-card :loading="value">
      <v-card-text class="mt24">
        {{text}}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DialogLoading",
  props: {
    value: Boolean,
    text: String,
  },
};
</script>